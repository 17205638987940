import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import { colors } from "../components/theme";
import { UserVuetifyPreset } from "vuetify/types/services/presets";

const opts: Partial<UserVuetifyPreset> = {
  lang: {
    locales: { de, en },
    current: "de",
  },
  theme: {
    themes: { light: colors },
  },
  icons: {
    iconfont: "mdi",
  },
};

Vue.use(Vuetify);
const vuetify = new Vuetify(opts);
export default vuetify;
