



































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import AVersionInfo from "@auditcloud/components/snippets/AVersionInfo.vue";

@Component({
  components: {
    AVersionInfo,
  },
})
export default class AMaintenanceOngoingInfo extends Vue {
  @Prop({ default: null })
  dbVersions!: { expected: number; actual: number } | null;
}
